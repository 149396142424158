export default async (response: any) => {
	try {
		if (response?.statusCode && response.statusCode === 403) {
			const { data } = await useFetch("/api/user/logout", { method: "POST" });

			if (data?.value?.ok === true) {
				location.reload();
			}
		}
		return response;
	} catch (e) {}
};
