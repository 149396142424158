<template>
	<div class="tab-pane-lead mb-7" v-if="showLead">
		<h3 class="font-set-7 m-0">Sehr geehrte Leserin,<br />sehr geehrter Leser,</h3>
		<p class="mb-3">
			wir freuen uns, Ihnen eine auf Sie zugeschnittene Nachrichtenerfahrung bieten zu können. Ab sofort finden Sie in unserem
			Empfehlungsbereich eine kuratierte Auswahl an Artikeln, die speziell auf Ihre Interessen abgestimmt sind.
		</p>
	</div>
	<Loading v-show="loading"></Loading>
	<template v-if="!loading">
		<MoleculesSection :title="'Exklusive Empfehlungen für Sie'" :design="'box-highlight'" v-if="exclusiveRecommendations.length > 0">
			<OrganismsTeaserGroup
				:data-tracking-module="1"
				:data-tracking="getModuleTrackingData('Exklusive Empfehlungen für Sie')"
				:layout="'8'"
				:teasers="addTeaserTrackingData(exclusiveRecommendations, false)"
			></OrganismsTeaserGroup>
		</MoleculesSection>

		<MoleculesSection :title="'Das ist seit Ihrer letzten Anmeldung passiert'" :design="'box'" v-if="sinceLastLogin.length > 0">
			<OrganismsTeaserGroup
				:data-tracking-module="1"
				:data-tracking="getModuleTrackingData('Das ist seit Ihrer letzten Anmeldung passiert')"
				:layout="'8'"
				:teasers="addTeaserTrackingData(sinceLastLogin, false)"
			></OrganismsTeaserGroup>
		</MoleculesSection>

		<MoleculesSection
			:title="'Meine Region'"
			:design="'box'"
			:sectionLinks="
				location.length > 0
					? [
							{ label: location[0].label, url: location[0].href },
							{
								icon: AtomsIconEdit,
								title: 'Einstellungen bearbeiten',
								url: userSettingsUrl,
							},
						]
					: []
			"
		>
			<OrganismsTeaserGroup
				v-if="location.length > 0"
				:data-tracking-module="1"
				:data-tracking="getModuleTrackingData('Meine Region')"
				:layout="'1'"
				:teasers="addTeaserTrackingData(locationArticles, false)"
			></OrganismsTeaserGroup>
			<MoleculesModuleFallback
				title="Noch keine Region ausgewählt?"
				text="Wählen Sie jetzt Ihre Lieblingsregion und erhalten Sie an dieser Stelle passende Artikelempfehlungen"
				:button="{
					label: 'Region auswählen',
					url: userSettingsUrl,
				}"
				v-else
			></MoleculesModuleFallback>
		</MoleculesSection>

		<MoleculesSection
			:title="`Weil Sie sich für ${resortLabel} interessieren`"
			:design="'box'"
			v-if="resortArticles.length > 0"
			:sectionLinks="resortSectionLinks"
		>
			<OrganismsTeaserSlider
				:data-tracking-module="1"
				:data-tracking="getModuleTrackingData(`Weil Sie sich für ${resortLabel} interessieren`)"
				:articles="addTeaserTrackingData(resortArticles, false)"
			></OrganismsTeaserSlider>
		</MoleculesSection>

		<MoleculesSection :title="`Alles zum Schwerpunkt ${audienceLabel}`" :design="'box'" v-if="audiencesArticles.length > 0">
			<OrganismsTeaserSlider
				:data-tracking-module="1"
				:data-tracking="getModuleTrackingData(`Alles zum Schwerpunkt ${audienceLabel}`)"
				:articles="addTeaserTrackingData(audiencesArticles, false)"
			></OrganismsTeaserSlider>
		</MoleculesSection>

		<MoleculesSection :title="''" :design="'box'">
			<PersonalNewsletter></PersonalNewsletter>
		</MoleculesSection>

		<MoleculesSection
			:design="'box'"
			:title="'Meine Themen'"
			:sectionLinks="
				topics.length > 0
					? [
							{
								icon: AtomsIconEdit,
								title: 'Einstellungen bearbeiten',
								url: userSettingsUrl,
							},
						]
					: []
			"
		>
			<OrganismsTeaserGroup
				v-if="myTopicsArticles.length > 0"
				:data-tracking-module="1"
				:data-tracking="getModuleTrackingData(`'Meine Themen`)"
				:layout="'8'"
				:teasers="addTeaserTrackingData(myTopicsArticles, false)"
				class="u-recommendation__my-topics"
			></OrganismsTeaserGroup>
			<MoleculesModuleFallback
				:title="topics.length > 0 ? 'Keine Artikel zu Ihren Themen gefunden?' : 'Noch keine Themen ausgewählt?'"
				:text="
					topics.length > 0
						? 'Wählen Sie jetzt weitere Lieblingsthemen und erhalten Sie an dieser Stelle passende Artikelempfehlungen'
						: 'Wählen Sie jetzt Ihre Lieblingsthemen und erhalten Sie an dieser Stelle passende Artikelempfehlungen'
				"
				:button="{
					label: 'Meine Themen konfigurieren',
					url: userSettingsUrl,
				}"
				v-else
			></MoleculesModuleFallback>
		</MoleculesSection>
	</template>
</template>

<script lang="ts" setup>
	import PersonalNewsletter from "./PersonalNewsletter.vue";
	import useClientSideConfig from "~/composables/useClientSideConfig";
	import { addTeaserTrackingData } from "~/composables/useTrackComponents";

	const route = useRoute();
	const userSettingsUrlDefault = `${route.path.replace(/\/$/, "")}/meine-themen`;
	const userSettingsUrl =
		userSettingsUrlDefault.indexOf(useClientSideConfig().my_page_link) === -1
			? useClientSideConfig().my_page_link.replace(/\/$/, "") + userSettingsUrlDefault
			: userSettingsUrlDefault;

	const AtomsIconEdit = resolveComponent("AtomsIconEdit");
	const loading = ref(false);

	const topics = ref([]);
	const myTopicsArticles = ref([]);
	const location: Ref<any[]> = ref([]);
	const locationArticles = ref([]);
	const availableLocations = ref([]);
	const sinceLastLogin = ref([]);
	const exclusiveRecommendations = ref([]);
	const resortLabel = ref("");
	const resortArticles = ref([]);
	const resortSectionLinks: Ref<any[]> = ref([]);
	const audienceLabel = ref("");
	const audiencesArticles = ref([]);

	const props = withDefaults(
		defineProps<{
			showLead?: boolean;
		}>(),
		{
			showLead: true,
		},
	);

	const emit = defineEmits(["loading"]);

	function getModuleTrackingData(title) {
		return JSON.stringify({
			contentOrigin: "recommendation",
			zone: "main_content",
			module_position: -1,
			module_type: "content",
			title_visible: title,
			title_internal: title,
		});
	}

	onMounted(async () => {
		if (import.meta.client) {
			loading.value = true;
			emit("loading", true);
			const [
				favouriteTopicsResponse,
				favouriteTopicsArticlesResponse,
				availableLocationsResponse,
				favouriteLocationResponse,
				favouriteLocationArticlesResponse,
				sinceLastLoginArticlesResponse,
				exclusiveRecommendationsResponse,
				resortResponse,
				audiencesResponse,
			] = (
				await Promise.allSettled(
					(
						await Promise.allSettled([
							fetch(`/api/no-cache/user/user-favourites/favourite-topics`).then((response) => response.json()),
							fetch(`/api/no-cache/user/user-favourites/articles-by-selected-topics`).then((response) => response.json()),
							fetch(`/api/no-cache/user/user-favourites/available-locations`).then((response) => response.json()),
							fetch(`/api/no-cache/user/user-favourites/favourite-location`).then((response) => response.text()),
							fetch(`/api/no-cache/user/articles-by-selected-location`).then((response) => response.json()),
							fetch(`/api/no-cache/user/articles-since-last-login`).then((response) => response.json()),
							fetch(`/api/no-cache/user/exclusive-recommendations`).then((response) => response.json()),
							fetch(`/api/no-cache/user/ressorts`).then((response) => response.json()),
							fetch(`/api/no-cache/user/audiences`).then((response) => response.json()),
						])
					)
						.map((promise: any) => (promise.status === "fulfilled" ? promise.value : []))
						.map((response: any) => useLogoutOn403Response(response)),
				)
			).map((promise: any) => (promise.status === "fulfilled" ? promise.value : []));

			topics.value = favouriteTopicsResponse || [];
			myTopicsArticles.value =
				(favouriteTopicsArticlesResponse || []).map((teaserItem: any) => {
					teaserItem.bookmarkVisible = true;
					teaserItem.topicVisible = true;
					return teaserItem;
				}) || [];
			availableLocations.value = availableLocationsResponse || [];
			location.value = availableLocations.value.filter((l: any) => l.label === favouriteLocationResponse) || "";
			locationArticles.value =
				(favouriteLocationArticlesResponse?.articles || []).map((teaserItem: any) => {
					teaserItem.bookmarkVisible = true;
					teaserItem.placeVisible = true;
					return teaserItem;
				}) || [];
			sinceLastLogin.value =
				(sinceLastLoginArticlesResponse?.articles || []).map((teaserItem: any) => {
					teaserItem.bookmarkVisible = true;
					return teaserItem;
				}) || [];
			exclusiveRecommendations.value =
				(exclusiveRecommendationsResponse?.articles || [])
					.map((teaserItem: any) => {
						teaserItem.bookmarkVisible = true;
						return teaserItem;
					})
					.slice(0, 3) || [];
			const isWhitelistedResort = useClientSideConfig().resortWhitelist.includes(resortResponse?.label?.toLowerCase());
			resortArticles.value =
				(isWhitelistedResort
					? (resortResponse?.articles || []).map((teaserItem: any) => {
							teaserItem.bookmarkVisible = true;
							teaserItem.updatedTimeVisible = true;
							teaserItem.placeVisible = true;
							teaserItem.topicVisible = false;

							return teaserItem;
						})
					: []) || [];
			resortLabel.value = resortResponse?.label || "";
			resortSectionLinks.value = [
				{
					label: "Weitere Artikel",
					url: `/lokales/${resortResponse?.label?.toLowerCase()}`,
				},
			];
			audiencesArticles.value =
				(audiencesResponse?.articles || []).map((teaserItem: any) => {
					teaserItem.bookmarkVisible = true;
					teaserItem.updatedTimeVisible = true;
					teaserItem.placeVisible = true;
					teaserItem.topicVisible = false;

					return teaserItem;
				}) || [];
			audienceLabel.value = audiencesResponse?.label || "";
			loading.value = false;
			emit("loading", false);
			// Register tracking event module engaged on components. Wait one moment to assure that components are all mounted
			window.setTimeout(() => {
				document.body.dispatchEvent(new CustomEvent("avo-refresh-module-engaged-viewed"), 1000);
			});
		}
	});
</script>

<style lang="scss">
	.u-recommendation__my-topics {
		.u-article-type-flag {
			display: none;
		}
	}
</style>
